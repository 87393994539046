<script>
export default {
  props: {
    value: {
      type: undefined, // Can be any type
    },
  },
  computed: {
    checkboxValue: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('input', e)
      },
    },
  },
}
</script>
