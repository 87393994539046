<template>
  <div>
    <input
      id="form__privacyPolicy"
      v-model="checkboxValue"
      class="form-check-input"
      name="privacyPolicy"
      type="checkbox">
    <i18n
      path="globals.checkboxes.privacyPolicy.text"
      tag="label"
      class="form-check-label"
      for="form__privacyPolicy">
      <template v-slot:termsAndConditions>
        <a
          :href="localePath({ name: 'page-slug', params: { slug: $store.state.cart.conditionsPageSlug }})"
          class="link link-underlined"
          target="_blank"
          v-on="$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal ? { click: (e) => { e.preventDefault(); e.stopPropagation(); $refs.modalTermsAndConditions.show()}} : {}"
        >{{ $t('globals.checkboxes.privacyPolicy.termsAndConditionsLinkText') }}</a>
      </template>
      <template v-slot:privacyPolicy>
        <a
          v-tooltip="$t('globals.checkboxes.privacyPolicy.privacyPolicyLinkTooltip')"
          :href="localePath({ name: 'page-slug', params: { slug: slugPrivacyPolicy }})"
          class="link link-underlined"
          target="_blank"
          v-on="$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal ? { click: (e) => { e.preventDefault(); e.stopPropagation(); $refs.modalPrivacyPolicy.show()}} : {}"
        >{{ $t('globals.checkboxes.privacyPolicy.privacyPolicyLinkText') }}</a>
      </template>
      <template v-slot:star>
        <RequiredSign v-if="$themeSettings.components.PageCheckoutInfo.privacyPolicyLink.showRequiredSign"/>
      </template>
    </i18n>
    <TextPageModal
      v-if="$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal"
      ref="modalTermsAndConditions"
      :text-page-slug="$store.state.cart.conditionsPageSlug"
    />
    <TextPageModal
      v-if="$themeSettings.components.PageCheckoutInfo.openTextPageLinksInModal"
      ref="modalPrivacyPolicy"
      :text-page-slug="slugPrivacyPolicy"
    />
  </div>
</template>

<script>
import CheckboxMixin from '@theme/components/cart/PageCheckoutInfo/form/checkboxes/CheckboxMixin'
import RequiredSign from '@theme/components/atom/RequiredSign'
import TextPageModal from '@theme/components/utils/TextPageModal'

export default {
  components: {
    RequiredSign,
    TextPageModal,
  },
  mixins: [CheckboxMixin],
  data() {
    return {
      slugPrivacyPolicy: this.$t('globals.pageSlugs.privacyPolicy'),
    }
  },
}
</script>
